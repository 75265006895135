@import "../../styles/variables";

.test {
  border: $border-standard;
  border-radius: $radius-standard;
  overflow: hidden;
}

.tooltip {
  z-index: 99999999 !important;
  pointer-events: auto !important;
  background-color: #001a3e !important;
  opacity: 1 !important;
  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
    background-color: #001a3e !important;
  }
}

.selectListLength {
  display: flex;
  align-items: center;
}

h6 {
  margin-right: 10px;
  color: $text-color;
}
.selector {
  margin: 0;
  width: 70px;
  height: 40px;
  margin-right: 10px;
  border: $border-double;
  border-radius: $radius-standard;
  color: $brand-grey;
  font-size: $font-standard;
}

.clickableRow {
  cursor: pointer;
  &:hover {
    background: lightgrey !important;
  }
}
.clickableCell {
  cursor: pointer;
  img {
    border-radius: 4px;
  }
}

.tablePagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 10px;
  & > div {
    &:first-of-type {
      min-width: 250px;
    }
  }
  & .tablePagesControl {
    display: flex;
    flex-wrap: wrap;
    & button {
      padding: 8px 12px;
      background: $white;
      border: $border-standard;
      font-size: 16px;
      cursor: pointer;
      width: fit-content;
      color: $brand-grey;
      &:first-of-type {
        border-radius: 4px 0 0 4px;
        border-right: 0;
      }
      &:last-of-type {
        border-radius: 0 4px 4px 0;
        border-left: 0;
      }
      &:hover {
        background-color: $light-grey;
        border-color: #dee2e6;
      }
      &:disabled {
        pointer-events: none;
      }
    }

    & .pageSelector {
      width: 40px;
      border: $border-standard;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background-color: $light-grey;
        border-color: #dee2e6;
      }
    }
    & .pageSelectorActive {
      color: $white;
      background: $brand-navy;
      border-color: $brand-navy;
      &:hover {
        background: $brand-navy;
        border-color: $brand-navy;
      }
    }
  }
}
