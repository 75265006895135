@import '../../styles/variables';

@keyframes select-loading {
  0% {
    background: $brand-navy;
  }
  100% {
    background: $brand-grey;
  }
}

.linkSelect {
  display: inline-block;
  //padding: $spacing-vertical $spacing-horizontal;
  //margin: $spacing-vertical;

  padding: 5px;
  //padding-right:40px;
  margin-bottom:10px;
  width: 400px;

  border: $border-double;
  border-radius: $radius-standard;
  color: $brand-grey;
  font-size: $font-standard;

  -webkit-apperance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 95% 50%;
}

.linkSelectLoader {
  background: $brand-navy;
  background-size: 100% 200%;
  min-width: 150px;
  height: 40px;
  width: calc(100% - 20px);

  animation: select-loading 1.25s linear infinite;
  animation-direction: alternate;
}
