@import '../../../../styles/_variables.scss';

.cardElement {
  width: 100%;
  min-height: 160px;
  flex-flow: wrap;
  font-size: $font-standard;
  background: $white;
  padding: 20px;
  margin-bottom: 10px;
  & span {
    word-break: break-all;
  }
  & img {
    object-fit: cover;
    min-width: 100px;
    max-width: 100px;
    height: 100px;
    margin-right: 20px;
    border-radius: $radius-standard;
  }
  &:hover {
    background: $whitesmoke;
  }
  &Offline {
    border: 2px solid $background-grey;
    border-radius: 4px;
  }
  &Normal {
    border: 2px solid $brand-navy;
    border-radius: 4px;
  }
  &Warning {
    border: 2px solid $brand-yellow;
    border-radius: 4px;
  }
  &Critical {
    border: 2px solid $brand-red;
    border-radius: 4px;
  }
  &Unauthorised {
    border: 2px solid $brand-grey;
    border-radius: 4px;
    cursor: default;
    &:hover {
      background: initial;
    }
  }
}

.vesselName {
  color: $brand-navy;
  font-size: 18px;
}

.vesselRow {
  margin: 5px 0;
}

.vesselFriendlyName {
  font-size: $font-standard;
}

.vesselDevices {
  display: flex;
  flex-flow: column;
  font-size: $font-standard;
}

.leftSide {
  width: 100%;
}

.rightSide {
  margin-left: 190px;
  margin-bottom: 15px;
  button {
    margin-left: 0 !important;
  }
}
