@import '../../../../styles/variables';

.sectionNotes {

  & h4 {
    padding: 0;
  }

  & .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & .notesContainer {
    margin-top: $spacing-horizontal;

    & .noteWrapper {
      padding: $spacing-horizontal;
      display: flex;

      &:nth-of-type(odd) {
        background-color: #eee;
      }

      & .content {
        flex: 1;

        & .titlePart {
          & .name {
            font-size: $font-subtitle;
            color: $brand-navy;
            font-weight: 600;
            margin-right: 5px;
          }
          & .date {
            color: $brand-grey;
            font-size: $font-standard;
          }
        }
        & .note {
          color: $brand-grey;
          font-size: $font-standard;
          margin-top: $spacing-horizontal;
        }
      }
      & .removeBtn {
        margin-top: 10px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: $brand-red;
        border: none;

        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          cursor: pointer;
        }

        & img {
          max-width: initial !important;
          width: 15px;
          height: auto;
        }
      }
    }
  }
  & .textareaWrapper {
    display: flex;
    flex-direction: column;
    margin-top: $spacing-horizontal;

    & .form {
      display: flex;
      flex-direction: column;

      & .textarea {
        //font-family: 'Rubik', sans-serif;
        border: 2px solid #aaa;
        border-radius: 4px;
        padding: $spacing-horizontal;
        resize: none;
      }

      & button {
        width: fit-content;
        margin: 0 auto;
        margin-top: $spacing-horizontal;
      }
    }
  }
}

.warningHeader {
  & svg{
    margin: 5px;
  }
  display: flex;
  border-radius: 3px;
  margin-right: 10px;
  background-color: $brand-yellow;
  align-items: center;
  justify-content: center;
  width: 97%;
}

.routineHeader {
  font-size: $font-subtitle;
  padding: 10px;
}

.button{
  margin-bottom: 10px;
  width: 170px;
}