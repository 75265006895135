@import "../../styles/variables";

.Input {
  width: 100%;
  position: relative;
  display: flex;
  .labelInactive {
    position: absolute;
    left: 12px;
    top: 21px;
    width: fit-content;
    height: fit-content;
    background-color: transparent;
    pointer-events: none;
    transition: 0.2s;
    color: #cbced1;
    font-size: 16px;
  }
  .labelInactiveBlank {
    position: absolute;
    left: 10px;
    top: 2px;
    width: fit-content;
    height: fit-content;
    background-color: transparent;
    pointer-events: none;
    transition: 0.2s;
    color: transparent;
    font-size: 14px;
    color: $brand-navy;
    background-color: #fff;
    border-radius: 4px;
    padding: 0 3px;
  }
  .labelActive {
    position: absolute;
    left: 10px;
    top: 2px;
    width: fit-content;
    height: fit-content;
    background-color: #fff;
    border-radius: 4px;
    padding: 0 3px;
    transition: 0.2s;
    font-size: 14px;
    color: $brand-navy;
    z-index: 1;
  }
  .labelDisabled {
    position: absolute;
    left: 10px;
    top: 2px;
    width: fit-content;
    height: fit-content;
    background-color: #fff;
    border-radius: 4px;
    padding: 0 3px;
    transition: 0.2s;
    font-size: 14px;
    color: $brand-grey;
    z-index: 1;
  }
  .inputInactive {
    height: $height-standard;
    display: block;
    margin: 10px 0;
    padding: $spacing-vertical;
    border-radius: $radius-standard;
    border: $border-double;
    font-size: 16px;
    color: $brand-navy;
    width: 100%;
    transition: 0.2s;
  }
  .inputInactiveBlank {
    height: $height-standard;
    display: block;
    margin: 10px 0;
    padding: $spacing-vertical;
    border-radius: $radius-standard;
    border: $border-double;
    border-color: $brand-navy;
    font-size: 16px;
    color: $brand-navy;
    width: 100%;
    transition: 0.2s;
  }
  .inputInactiveBlank:disabled,
  .inputInactiveBlank:read-only {
    height: $height-standard;
    display: block;
    margin: 10px 0;
    padding: $spacing-vertical;
    border-radius: $radius-standard;
    border: $border-double;
    border-color: $brand-grey;
    font-size: 16px;
    color: $brand-grey;
    width: 100%;
    transition: 0.2s;
    background: #fff;
  }
  .inputActive {
    height: $height-standard;
    display: block;
    margin: 10px 0;
    padding: $spacing-vertical;
    border-radius: $radius-standard;
    border: $border-double;
    border-color: $brand-navy;
    font-size: 16px;
    color: $brand-navy;
    width: 100%;
    transition: 0.2s;
  }
  .inputActiveError {
    height: $height-standard;
    display: block;
    margin: 10px 0;
    padding: $spacing-vertical;
    border-radius: $radius-standard;
    border: $border-double;
    border-color: $brand-navy;
    font-size: 16px;
    color: $brand-red;
    width: 100%;
    transition: 0.2s;
  }
  .inputActive:disabled,
  .inputActive:read-only {
    height: $height-standard;
    display: block;
    margin: 10px 0;
    padding: $spacing-vertical;
    border-radius: $radius-standard;
    border: $border-double;
    border-color: $brand-grey;
    font-size: 16px;
    color: $brand-grey;
    width: 100%;
    transition: 0.2s;
    background: #fff;
  }
}
