@import '../.../../../styles/variables';

.tabButtonsWrapper {
  //padding: $spacing-vertical $spacing-horizontal;
  flex: 1;
  margin-right: 300px;
  &Small {
    margin-right: 10px;
  }
}

.tabButtonsWrapperSpace {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $white;
  padding: $spacing-vertical $spacing-horizontal;
}

.tabButton {
  background: transparent;
  cursor: pointer;
  border: none;
  border-bottom: 2px solid transparent;
  padding: 11px 10px 10px 10px;
  font-size: $font-standard;
  outline: none;
  width: fit-content;
  color: $text-color;
  &:first-child{
    padding-left: 0;
  }

  &Active {
    color: $brand-navy;
    border-bottom: 2px solid $brand-navy !important;
  }
}
