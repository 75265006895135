.vesselMarkerWrapper {
  position: relative;
  margin: auto;
  width: 40px;
  height: 40px;
  transform: translate(-50%, -100%);
  &Table {
    transform: none;
  }
  & .vesselMarker {
    max-width: 40px;
    height: auto;
  }

  & .domainMarker {
    height: 30px !important;
    max-width: 30px !important;
    left: 5px;
    top: 5px;
    position: absolute;
  }

  & .vesselCardWrapper {
    display: none;
  }

  &:hover {
    z-index: 99;
    & .vesselCardWrapper {
      z-index: 999;
      display: block;
      position: absolute;
      width: 440px;
    }
  }
}
